import consumer_flight_order_change_detail from '@/lib/data-service/haolv-default/consumer_flight_order_change_detail'
import consumer_air_ticket_flightRules from '@/lib/common-service/consumer_air_ticket_flightRules' // 获取退改规则
import NewChangeClause from "@/page/admin/travel-book/flight/component/newChangeClause/index.vue";
import ApproveTimeLine from '@/page/admin/office/my-apply/components/approveTimeLine'
import consumer_air_ticket_cancelChangePay from '@/lib/data-service/haolv-default/consumer_air_ticket_cancelChangePay'
import ChangeClausePopup from '@/page/admin/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import moment from "moment";
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import PolicyTypeLabel from '@/page/admin/travel-book/flight/component/policyTypeLabel/index.vue'
import consumer_air_ticket_ApprovalMethod from '@/lib/data-service/haolv-default/consumer_air_ticket_ApprovalMethod'
export default {
	data() {
		return {
			type: 'detail',
			detail: null,
			ruleShow: false,
			ruleLoading: false,
			ruleText: {}, // 退改规则
			approvalMethod: null,
		};
	},
	computed: {

	},
	components: {
		ApproveTimeLine,
		NewChangeClause,
		ChangeClausePopup,
		PolicyTypeLabel
	},
	filters: {
		formatTime(val) {
			return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
		},
		filterWeek(val) {
			if (val === '') {
				return ''
			}
			let day = moment(val).day();
			if (day === 0) {
				return '周日'
			} else if (day === 1) {
				return '周一'
			} else if (day === 2) {
				return '周二'
			} else if (day === 3) {
				return '周三'
			} else if (day === 4) {
				return '周四'
			} else if (day === 5) {
				return '周五'
			} else if (day === 6) {
				return '周六'
			} else {
				return ''
			}
		},
	},
	methods: {
		showThisRuleList(index, val) {
			this.ruleText = {}
			this.ruleShow = true
			let data = {
				segmentNo: val.segmentNo
			}
			this.ruleLoading = true
			consumer_air_ticket_flightRules(data).then(res => {
				this.ruleLoading = false
				this.ruleText = res.datas.rsData
			}).catch(() => {
				this.ruleLoading = false
			})
		},
		/*setTypeLabelColor(val) {
			//"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
			//"supplierText": "其他渠道(供应商)【平台优选】",
			// "bigCustomers": "QCYX",
			//"bigCustomersText": "企业协议(大客户)",
			//"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
			//"lowPriceText": "低价推荐",
			// "website": "21,22,23,24,25,6,CXF1,WS",
			//"websiteText": "航司官网(官网)",
			//"platformProprietary": "61",
			//"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
			const btoList = ['1','2','3','4','12','13','9','OPL9','OPL6','OPL11','OPL18','OPL20','OPL16','60','FD','NFD','VARI']
            const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30']
            const owList = ['21','22','23','24','25','31','6','CXF1','WS','GW311','GW312','OPL17','OPL23']
            const qcList = ['QCYX']
            const ptList = ['61']
			if (btoList.indexOf(val) > -1) {
				return 'type1'
			} else if (lprList.indexOf(val) > -1) {
				return 'type2'
			} else if (owList.indexOf(val) > -1) {
				return 'type3'
			} else if (qcList.indexOf(val) > -1) {
				return 'type4'
			} else if (ptList.indexOf(val) > -1) {
				return 'type5'
			} else {
				return 'type6'
			}
		},*/
		getStatusColor(val) {
			//  31=改签中，33=待补款，34=已补款，35=改签成功，36=改签失败，37=已取消
			if (val === 31 || val === 33 || val === 34) {
				return 'status2'
			} else if (val === 35) {
				return 'status1'
			} else {
				return 'status3'
			}
		},
		goEvectionApplyDetail() {
			this.$router.push({
				name: 'admin-my-apply-manage-apply-info',
				query: {
					applyId: parseInt(this.detail.applyId),
					approveType: 1,
					type: 'info',
					lastMenu: 'adminMyApply'
				}
			})
		},
		payChange() {
			this.$router.push({
				name: "admin-pay",
				query: { orderNos: this.detail.changeOrderNo, /*evectionNo: item.evectionNo,*/ orderType: 1, pageType: 3 },
			});
		},
		cancelChange() {
			this.$confirm("确定要取消订单吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				let data = {
					changeNo: this.detail.changeOrderNo
				};
				consumer_air_ticket_cancelChangePay(data).then(res => {
					this.getDetail()
				});
			}).catch(() => {});
		},
		getDetail() {
			let chaOrderNo = this.$route.query.orderNo
			let psgNoList = this.$route.query.psgNoList
			let psgNoArr = []
			if (psgNoList !== undefined && psgNoList !== '' && psgNoList !== null) {
				psgNoArr = psgNoList.split(',')
			}
			consumer_flight_order_change_detail({ chaOrderNo, psgNoList: psgNoArr }).then(res => {
				console.log(res)
				this.detail = res.datas;
        // this.detail.applicationVO.url = 'https://yinzhilv-file-bucket-alpha.oss-cn-shenzhen.aliyuncs.com/beta/2023/05/30/16/eb2ed148-382c-4ea2-a9d9-45a2aa2ae57d/铨成商旅logo.png';
        if (this.detail.applicationVO.url) {
          let urlName = this.detail.applicationVO.url.split('/')[this.detail.applicationVO.url.split('/').length - 1];
          this.detail.applicationVO.urlName = `${urlName}`.length > 13 ? `${urlName.slice(0,12)}...` : `${urlName}`;
        }
				// if (res.datas.applyNo) {
				// 	this._approvals(res.datas.changeDetail.applyNo)
				// }
				if (this.detail.applyNo) {
					consumer_air_ticket_ApprovalMethod({applyNo: this.detail.applyNo}).then(res => {
						this.approvalMethod = res.datas.approvalMethod;
					});
				}
			})
		},
		checkInsuranceDetail(val) {
			this.loading = true
			const params = {
				insuranceOrderId: val.insuranceOrderId
			}
			consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
				const url = res.datas.epolicyUrl
				if (url) {
					window.open(url)
				}
			}).finally(() => {
				this.loading = false
			})
		},
		checkApplyDetail() {
			if (this.approvalMethod == 1) {
				// 我的申请
				this.$router.push({
					name: 'admin-my-apply-manage-apply-info',
					query: {
						applyId: this.detail.applyId,
						type: 'info',
						approveType: 8,
						lastMenu: 'adminMyApply'
					}
				})
			} else {
				// 待我审批
				this.$router.push({
					name: 'admin-my-apply-manage-noApprove-detail',
					query: {
						applyId: this.detail.applyId,
						type: 'audit',
						approveType: 8,
					}
				})
			}
		},
	},
	created() {},
	mounted() {},
	activated() {
		if (this.$route.query.type) {
			this.type = this.$route.query.type
		} else {
			this.type = 'detail'
		}
		this.getDetail()
	},
	deactivated() { },
	destroyed() { },
	watch: {

	},
};
